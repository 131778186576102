// Bootstrap Theming
body {
  overflow: hidden;
  height: 100vh;
  display: block;
  // color: "white";
  // background-color: var(--bs-dark);
  user-select: none;
}

#root {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  z-index: 1045;
}

$navbar-dark-active-color: var(--bs-warning) !important;
// $warning: #90e0ef;
// $warning: #29AAE1;

.accordion-button.collapsed {
  background: var(--bs-dark) !important;
  color: var(--bs-light) !important;
}

.accordion-button {
  background: var(--bs-dark) !important;
  color: var(--bs-light) !important;
  svg {
    fill: var(--bs-light) !important;
  }
}

.afterNone {
  ::after {
    display: none;
  }
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.menuAnimation {
  transition: 0.25s ease;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.2rem var(--bs-warning) !important;
}

// td{
//   padding: 0.25rem !important;
// }

tr {
  border-top: 2px solid var(--bs-dark) !important;
  border-bottom: 2px solid var(--bs-dark) !important;
}

.nav-link.active {
  background-color: var(--bs-warning) !important;
  color: var(--bs-dark) !important;
}

.nav-link {
  background-color: var(--bs-dark) !important;
  color: var(--bs-light) !important;
}

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

.menuAnimation {
  transition: 0.25s ease;
}

.logInBack {
  // background-image: url("./assets/loginback.svg");
  // background-position: center;
  // background-size: 300%;
  // background-repeat: no-repeat;
  // background-image: url("/assets/loginback.svg");
  background-image: url("/assets/back.svg");
  background-position: center;
  background-size: cover;
}

.loadModal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.3) 3%,
    rgba(0, 0, 0, 0.5) 6%,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 2000;
}

.removeFocus {
  box-shadow: none !important;
}

.Modal {
  position: absolute;
  padding: 0px;
  border: 0px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  // margin-right: -50%;
  transform: translate(-50%, -50%);
}

.AboutModal {
  background-image: url("/assets/back.svg");
  background-position: center;
  background-size: cover;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0.5rem);
  background-color: #000000c2;
  z-index: 1060 !important;
}

.closeButton {
  position: absolute;
  right: 0px;
  top: 0px;
}

.logoShadow {
  -webkit-filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.75));
}

// input[type="search"]::-webkit-search-cancel-button {
//   /* Remove default */
//   -webkit-appearance: none;
//   content: "";
//   display: block;
//   width: 25px;
//   height: 25px;
//   background-repeat: no-repeat;
//   background-size: contain;
//   /* note: this positioning will need to be tweaked for iOS */
//   background-position: center left;
//   background-image: url("./assets/closesearch.svg");
//   // /* Will place small red box on the right of input (positioning carries over) */
// }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

$editBgColor: var(--bs-primary);

$menu-items: 2;

%ball {
  background: $editBgColor;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  position: absolute;
  color: white;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}
.menu-open {
  display: none;
}
.menu-item {
  @extend %ball;
}

.menu {
  position: fixed;
  z-index: 30;
  right: 16px;
  bottom: 16px;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  // font-size:3rem;
  text-align: left;
  a:hover {
    color: white !important;
  }
}

.menu-open-button {
  @extend %ball;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  cursor: pointer;
  transform: scale(1.02, 1.02) translate3d(0, 0, 0);
}
.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

  @for $i from 1 through $menu-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 90ms+ (100ms * $i);
      transform: translate3d(-80px * $i, 0, 0);
    }
  }
}

.s1cont {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
}

.s1cont.daterange {
  gap: 0;
  border-bottom: 1px solid #d7d7d7;
  flex-direction: row;
}

.crm-browser-row {
  background-color: transparent;
  border-radius: 0.25rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  border-color: var(--bs-gray-700);
  border: 1px solid var(--bs-gray-700);
}

.crm-browser-row.selected-row {
  background-color: var(--bs-gray-700);
  border-color: #ffc107;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

// .s1-cont {
//   gap:0.5rem;
//   display:flex;
//   flex:1 1 100%;
// }

// .s1-cont-daterange {
//   display:flex;
//   flex:1 1 100%;
// }

.crm-user-page {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
}

.modalDialog {
  height: 75vh !important;
}

.modalDialog-content {
  height: 75vh !important;
}

.login-selects {
  height: 25vh !important;
}

.login-selects-content {
  height: 25vh !important;
}

.s1-hidden {
  display: none !important;
}

.crm-main-navigator {
  position: sticky;
  top: 0px;
  z-index: 1030;
  // height: 3.5rem;
}

.crm-menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.5rem;
  min-height: 3.5rem;
  font-size: 1.3rem;
}

#crm-top-bar {
  // height: 3.5rem;
  font-size: 1.2rem;
}

.crm-menu-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.s1-web-menu {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  max-height: 100%;
  overflow-y: auto;
}

.s1-web-menu-item {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 3.5rem;
  min-height: 3.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: white;
  font-size: 1.2rem;
}
.s1-web-menu-item > div > svg {
  font-size: 1.5rem;
  margin-right: 1.5rem;
}

.s1-web-menu-item.current-folder {
  background-color: var(--bs-secondary);
  // padding: 1rem 1rem;
  height: 4rem;
}

.crm-field {
  border-bottom: 1px solid #b9b9b9;
  border-radius: 0;
  border-width: 0 0 1px 0;
}

input,
textarea {
  background: transparent;
  color: white;
}

// .crm-search-on-list {
//   width: 100px;
//   -webkit-transition: all .5s ease;
//   -moz-transition: all .5s ease;
//   transition: all .5s ease;
// }
// .crm-search-on-list:focus {
//   width: 200px;
// }

#noCaret::after {
  display: none;
}

.crm-image-on-list {
  background-position: 50% 50%;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
}

.crm-image-on-showroom {
  background-position: 50% 50%;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.crm-no-image-on-list {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.crm-list-data {
  padding: 0.25rem;
}

.crm-list-data-header {
  font-size: 20px;
  color: white;
  margin-bottom: 0.25rem;
}

.crm-list-data-header span:nth-child(1) {
  font-weight: 300;
  margin-right: 0.5rem;
}

.crm-list-data-header span:nth-child(2) {
  font-weight: 650;
}

.crm-list-data-detail span:nth-child(1) {
  color: rgb(177, 177, 177);
  font-weight: 400;
}

.crm-list-data-detail span:nth-child(2) {
  font-weight: 600;
}

.crm-list-data-detail {
  color: white;
  margin-right: 1rem;
  display: inline-block;
}

.side-menu-action {
  font-size: 1.4em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 0.5rem;
}

.crm-relative-job-menu {
  opacity: 0 !important;
}

.crm-relative-job-action {
  font-size: 1.4em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 0.5rem;
}

.crm-floating-with-icon {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #d7d7d7;
  color: white !important;
}

.crm-input {
  color: white !important;
}

.crm-input:disabled + label {
  opacity: 0.5;
}

.form-control:disabled {
  background-color: transparent !important;
}

.form-control[readonly] {
  background-color: transparent !important;
  opacity: 0.5;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: none !important;
  background-color: transparent !important;
}

.form-control {
  background-color: transparent;
}

.s1checkbox > label {
  color: white;
}

.form-floating > label {
  padding: 1rem 0rem !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.crm-detail-grid-view tbody {
  display: block;
  overflow-y: scroll;
}
.crm-detail-grid-view thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.crm-detail-grid-view tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.Login-error-Modal {
  max-width: 410px;
}

// .crm-backdrop-gridlines{
//   z-index: 1056;
// }

// .crm-dialog-gridlines{
//   z-index: 1057;
// }

#carousel {
  /* Ensure that the contents flow horizontally */
  overflow-y: auto;
  white-space: nowrap;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
}

#carousel.vertical {
  flex-direction: column;
}

/* 2018 spec - For Safari 11, Chrome 69+ */
#carousel.snap {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
}

#carousel.snap > div {
  scroll-snap-align: center;
}

#carousel.snap.vertical {
  flex-direction: column;
  scroll-snap-type: y mandatory;
}

/* 2015 spec - For Firefox, Edge, IE */
#carousel.snap {
  scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  -ms-scroll-snap-points-x: repeat(100%);
}

#carousel.snap.vertical {
  scroll-snap-points-x: initial;
  -ms-scroll-snap-points-x: initial;
  scroll-snap-points-y: repeat(100%);
  -ms-scroll-snap-points-y: repeat(100%);
}

/* Below here is styling, not important for the actual example, just to make it look nice. No need to look down here! */

// html, body {
//   height: 100%;
//   margin: 0;
//   padding: 0;
// }

#carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

#carousel > .snap-item {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 20px;
}

.input-required {
  border-bottom: 1px solid var(--bs-danger) !important;
}

.checkbox-required {
  border: 1px solid var(--bs-danger) !important;
}

.overlapped-on-another-modal {
  z-index: 1056 !important;
}

.crm-button-with-icon:focus {
  box-shadow: none !important;
}

.s1-no-report-data {
  display: inline-block;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background: #e2ebfc url("../public/no-report-data.svg") no-repeat;
}

.s1-no-search-data {
  display: inline-block;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background: #e2ebfc url("../public/no-browser-data.svg") no-repeat;
}

.modalDialog-content {
  background-color: var(--bs-gray-dark);
  border-top: 1px solid var(--bs-warning) !important;
}

// input[type=date]::-webkit-datetime-edit-text,
// input[type=date]::-webkit-datetime-edit-month-field,
// input[type=date]::-webkit-datetime-edit-day-field,
// input[type=date]::-webkit-datetime-edit-year-field {
//   -webkit-appearance: textfield;
//   opacity: 0%;
// }

// input[type=date].date-input-has-value::-webkit-datetime-edit-text,
// input[type=date].date-input-has-value::-webkit-datetime-edit-month-field,
// input[type=date].date-input-has-value::-webkit-datetime-edit-day-field,
// input[type=date].date-input-has-value::-webkit-datetime-edit-year-field {
//   opacity: 100%;
// }

// input[type=datetime-local]::-webkit-datetime-edit-text,
// input[type=datetime-local]::-webkit-datetime-edit-month-field,
// input[type=datetime-local]::-webkit-datetime-edit-day-field,
// input[type=datetime-local]::-webkit-datetime-edit-hour-field,
// input[type=datetime-local]::-webkit-datetime-edit-minute-field,
// input[type=datetime-local]::-webkit-datetime-edit-ampm-field,
// input[type=datetime-local]::-webkit-datetime-edit-day-field,
// input[type=datetime-local]::-webkit-datetime-edit-year-field {
//   opacity: 0%;
// }

// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-text,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-month-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-day-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-hour-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-minute-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-ampm-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-day-field,
// input[type=datetime-local].date-input-has-value::-webkit-datetime-edit-year-field {
//   opacity: 100%;
// }

// input[type=time]::-webkit-datetime-edit-text,
// input[type=time]::-webkit-datetime-edit-hour-field,
// input[type=time]::-webkit-datetime-edit-minute-field,
// input[type=time]::-webkit-datetime-edit-ampm-field {
//   opacity: 0%;
// }

// input[type=time].date-input-has-value::-webkit-datetime-edit-text,
// input[type=time].date-input-has-value::-webkit-datetime-edit-hour-field,
// input[type=time].date-input-has-value::-webkit-datetime-edit-minute-field,
// input[type=time].date-input-has-value::-webkit-datetime-edit-ampm-field {
//   opacity: 100%;
// }

// // input[type="date"]::-webkit-calendar-picker-indicator {
// //   display: none;
// // }

// input[type=datetime-local]::-webkit-calendar-picker-indicator {
//   display: none;
// }

// input[type=time]::-webkit-calendar-picker-indicator {
//   display: none;
// }

.bouncing-loader {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--bs-warning);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent;
  transition: background-color 5000s ease-in-out 0s;
}
